// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import { graphql } from 'gatsby'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import TopBar from '../top-bar'
import '../top-bar/style.less'

import BottomBar from '../bottom-bar'
import '../bottom-bar/style.less'

import ArkPageItemsLayouts from '../ark-page-items-layouts'
import '../ark-page-items-layouts/style.less'

import StandardPageWrapper from '../standard-page-wrapper'
import '../standard-page-wrapper/style.less'

import './style.less'

import flattenGatsbyNode from '../../methods/flatten-gatsby-node'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
// const { Fragment } = React

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Images
// ----------------------------------------------------------------------------
export const pageQuery = graphql`
  query DecadePageQuery($pathAuto: String) {
    thisPage: allFile(filter: { fields: { pathAuto: { eq: $pathAuto } } }) {
      nodes {
        fields {
          pathAuto
          breadcrumbsAuto {
            title
            slug
          }
        }
        childResources {
          title
          pageMeta {
            totalObjects
            objectsOnThisPage
            totalPages
            prev
            this
            next
          }
          objects {
            arkRef
            fileRef
            title
            description
            keywords
            ar
          }
        }
      }
    }
  }
`

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** Page */
const Page = ({ data, pageContext, ...props }) => {
  const {
    thisPage: { nodes: n1 },
    node = flattenGatsbyNode(n1[0]),
  } = data

  const { pathAuto, breadcrumbsAuto, title, objects, pageMeta } = node

  const pageSchema = {
    pageTitle: title,
    slug: pathAuto.substring(1),
    abstract: title,
    breadcrumbs: breadcrumbsAuto,
  }

  return (
    <StandardPageWrapper
      className="decade-page has-ark-objects"
      pageSchema={pageSchema}
      {...props}
    >
      <TopBar
        title={`${title}, Page ${pageMeta.this}`}
        breadcrumbsAuto={breadcrumbsAuto}
        current={pageMeta.this}
        total={pageMeta.totalObjects}
        pageSize={pageMeta.objectsOnThisPage}
        thisPath={pathAuto}
      />
      <ArkPageItemsLayouts nodes={objects} />
      <BottomBar
        current={pageMeta.this}
        total={pageMeta.totalObjects}
        pageSize={pageMeta.objectsOnThisPage}
        thisPath={pathAuto}
      />
    </StandardPageWrapper>
  )
}

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default Page
